//Gnav
$(function() {
	var scrollUp = function(){
	    var start_pos = 0,
	        ua = navigator.userAgent;
    if (ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
      $(window).scroll(function(e){
	      if($('.header ul').hasClass('active')){
          var current_pos = $(this).scrollTop();
          if (current_pos > start_pos) {
            $('.header ul').removeClass('active').stop(true, true).slideUp();
            $('.header__hamburger').removeClass('header__hamburger-active');
          }
        }
      });
    }
	};
	scrollUp();


	var timer = false;
	$(window).resize(function() {
		var windowWidth = window.innerWidth;
		if (timer !== false) {
			clearTimeout(timer);
		}
		timer = setTimeout(function() {
			if (windowWidth > 768) {
				$('.header__hamburger').removeClass('header__hamburger-active').next('nav').find('ul').removeAttr("style");
				scrollUp();
			}
		}, 100);
	});
	var hamburger = $('.header__hamburger');
	hamburger.on('click', function() {
		var windowWidth = window.innerWidth;
		if (windowWidth < 768) {
			if ($(this).hasClass('header__hamburger-active')) {
				$(this).removeClass('header__hamburger-active');
				$(this).next('nav').find('ul').removeClass('active').stop(true, true).slideUp();
			} else {
				$(this).addClass('header__hamburger-active');
				$(this).next('nav').find('ul').addClass('active').stop(true, true).slideDown();
			}
		}
		return false;
	});
	$('.header__gnav a').on('click', function() {
		var windowWidth = window.innerWidth;
		if (windowWidth < 768) {
			$(this).parents('ul').removeClass('active').stop(true, true).slideUp();
			$(this).parents('nav').prev('.header__hamburger').removeClass('header__hamburger-active');
		}
	});
});


//nav move
function moveNav(){
	var item = $('.header__gnav').find('li');
	$('.header__hamburger').on('click', function(){
		item.removeClass('fadeInDown');
		if(!$(this).hasClass('header__hamburger-active')){
			 $.each(item, function(i) {
         $(this).delay(i * 60).queue(function() {
           $(this).addClass('fadeInDown');
           $(this).stop(true, true).dequeue();
         });
       });
		} else {
			item.removeClass('fadeInRight');
		}
	});
};

moveNav();


$('.is-moveUp').css('visibility','hidden');



//MoveUp
function MoveUp(){
 var windowHeight = $(window).height(),
     topWindow = $(window).scrollTop(),
     Move = $('.is-moveUp');
 for(var i = 0, len = Move.length; i < len; i++) {
   var targetPosition = Move.eq(i).offset().top;
   if(topWindow > targetPosition - windowHeight + 100){
     Move.eq(i).addClass("fadeInUp");
   }
 }
}

$(function(){
	var timer = null;
  $(window).on({
	  load:function(){
		  MoveUp();
	  },scroll:function(){
		  clearTimeout( timer );
      timer = setTimeout(function() {
        MoveUp();
      }, 0 );
	  },touchmove: function(){
		  MoveUp();
	  }
  })
});


//news
$(function(){
	$('.is-switch').on('click', function(){
		$(this).toggleClass('u_switch-active').parents('.news').find('.news__item:gt(4)').slideToggle();
		return false;
	});
})

//pagetopMove
var pagetop = function(){
	var _windowHt = ($(window).height()) / 2,
	    _pagetop = $('.is-pagetop');
  $(window).scroll(function () {
    if ($(this).scrollTop() > _windowHt) {
      _pagetop.addClass('pagetop-transX');
    } else {
      _pagetop.removeClass('pagetop-transX');
    }
  });
  _pagetop.on('click', function () {
    $('body, html').animate({ scrollTop: 0 }, 500);
    return false;
  });
}

pagetop();


//tel
var ua = navigator.userAgent.toLowerCase();
var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);

if (!isMobile) {
    $('a[href^="tel:"]').on('click', function(e) {
        e.preventDefault();
    });
}

// Scroll
$(function() {
	$('.is-scroll').click(function() {
		var speed = 800;
		var href = $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top + 'px';
		$("html, body").animate({
			scrollTop: position
		}, speed);
		return false;
	});
});